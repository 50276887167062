import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import PageHead from "../components/pageHead";
import TeaserInsight from "../components/teaserInsight";

import Layout from "../layouts/default-layout";

const InsightsPage = ({ path, data }) => {
  const intl = useIntl()
  const content = data.sanityInsights
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.headline ? content.headline[intl.locale] : null;  
  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path}>
      <PageHead
        headline={title}
        subline={content.headline && content.headline[intl.locale]}
        backgroundImage={content.poster && content.poster.asset ? content.poster.asset.gatsbyImageData.images.fallback.src : null}
        textWhite={content.lightHeader}
      />
      <div className="pt-8 mb-24">
        <div className="tro-default-padding-x">
          <div className="tro-grid-responsive">
            {content.insights.map((item, index) => (
              <div
                key={index}
                className="mb-8 cursor-pointer medium:mb-14 col-span-6 large:col-span-4"
              >
                <TeaserInsight
                  item={item}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InsightsPage;



export const query = graphql`
query InsightsPage {
  sanityInsights {
    title {
      de
      en
    }
    meta_title {
      de
      en
    }
    meta_description {
      de
      en
    }
    meta_noindex
    headline {
      de
      en
    }
    poster {
      asset {
        url
        gatsbyImageData(fit: CROP, placeholder: BLURRED, width: 1800, height: 1200, formats: JPG)
      }
    }
    lightHeader
    insights {
      ... Insight
    }
  }
}
`;

